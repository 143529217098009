<template>
  <div>
    <!-- 动态列表 -->
    <div class="container">
      <div class="content">
        <!-- 标题 -->
        <div class="content-header">
          <div class="content-title">大赛动态</div>
        </div>

        <!-- 动态列表 -->
        <div v-for="(news, idx) in newslist" :key="idx" class="content-info">
          <div class="news-body">
            <div class="news-img" @click="getNews(news.id)">
              <el-image class="img" :src="news.coverSrc" />
            </div>
            <div class="news-info">
              <div class="news-title" @click="getNews(news.id)">
                {{ news.title }}
              </div>
              <div class="news-text">
                {{ htmlFilter(news.content) }}
              </div>
            </div>
          </div>
        </div>

        <!-- 分页栏 -->
        <div v-if="screenWidth>1200" class="page">
          <pagination
            v-show="total>0"
            :total="total"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.limit"
            @pagination="fetchNewsList"
          />
        </div>
        <div v-else class="page">
          <div class="show-more" @click="fetchMoreNews">{{ showText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { getNewsList } from '@/api/homepage'

export default {
  components: { Pagination },
  data() {
    return {
      // 屏幕大小
      screenHeight: '',
      screenWidth: '',

      testPic: 'https://www.bupt.edu.cn/__local/6/DE/04/386D70A5906A30EB517282CB8C4_F236AB97_2F3F3.jpg',

      // 动态列表
      newslist: [],

      // 分页
      // listLoading: true,
      total: 1,
      totalPage: 0,
      listQuery: {
        page: 0,
        limit: 5
      },
      showText: '查看更多'
    }
  },
  created() {
  },
  mounted() {
    // 实时监听窗口大小的变化
    this.screenWidth = document.body.clientWidth
    this.screenHeight = document.body.clientHeight
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
        this.screenHeight = document.body.clientHeight
        // console.log('width ' + this.screenWidth)
        // console.log('height ' + this.screenHeight)
      })()
    }
    this.fetchNewsList(1)
  },
  methods: {
    previewFile(url) {
      return `${process.env.VUE_APP_PIC_URL}/${url}`
    },
    htmlFilter(html) {
      return html.replace(/<(.|\n)*?>/g, '')
    },
    // 大屏时：分页获取动态
    fetchNewsList() {
      console.log('fetch newslist')
      getNewsList(this.listQuery.page, this.listQuery.limit).then(res => {
        this.listQuery.page = res.data.pageNum
        this.totalPage = res.data.pages
        this.total = res.data.total
        const list = res.data.items
        const _this = this
        // eslint-disable-next-line no-return-assign
        list.forEach(it => it.coverSrc = _this.previewFile(it.coverSrc))
        this.newslist = list
      })
    },
    // 小屏时：获取更多的动态信息
    fetchMoreNews() {
      console.log('fetch more news')
      this.listQuery.page++
      if (this.listQuery.page > this.totalPage) {
        this.showText = '没有更多动态了'
        return
      }
      // 增加页数，将获取到的动态信息push到动态数组中
      getNewsList(this.listQuery.page, this.listQuery.limit).then(res => {
        this.listQuery.page = res.data.pageNum
        this.totalPage = res.data.pages
        this.total = res.data.total
        const list = res.data.items
        const _this = this
        // eslint-disable-next-line no-return-assign
        list.forEach(it => it.coverSrc = _this.previewFile(it.coverSrc))
        this.newslist = this.newslist.concat(list)
      })
    },
    // 根据动态 id 查看动态详情
    getNews(id) {
      console.log(id)
      this.$router.push('newsdetail/' + id)
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.container {
  width: 100%;
  background-color: var(--background-light-color);
  min-height: 50vh;
  /* border: 1px solid red; */
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .container {
    background-color: var(--background-color);
  }
}

.content {
  /* display: table-cell; */
  display: inline-block;
  margin: auto;
  margin-top: 30px;
  width: 70vw;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 70px;
  margin-bottom: 200px;

  /* border: 1px solid red; */
}

@media screen and (max-width: 1200px) {
  .content {
    width: 330px;
  }
}

.content-header {
  width: 90%;
  margin: auto;
  font-size: 18px;
  height: 60px;
  text-align: left;
  padding-top: 25px;
  border-bottom: 1px solid rgb(179, 175, 175);

}

.content-title {
  /* position: absolute;
  top: 30px; */
  text-align: 60px;
  width: 95%;
  margin: auto;
  padding-left: 7px;
}

.content-info {
  width: 90%;
  margin: auto;
  border-bottom: 1px solid rgb(179, 175, 175);
}

.news-body {
  width: 95%;
  margin: auto;
  /* border: 1px solid red; */
  height: 10vw;
  margin-bottom: 20px;
  margin-top: 20px;
  /* padding-bottom: 20px; */
  /* margin-bottom: 20px; */
}

@media screen and (max-width: 1200px) {
  .news-body {
    display: inline-block;
    width: 330px;
    height: 270px;
    margin: auto;
    /* min-height: 130px; */
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.news-img {
  float: left;
  /* display: inline-block; */
  width: 16vw;
  height: 10vw;
  /* border: 1px solid red; */
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .news-img {
    display: block;
    width: 290px;
    height: 180px;
  }
}

.img {
  transition: all 0.6s;
}

.img:hover {
  transform: scale(1.2);
}

.news-info {
  float: right;
  /* display: inline-block; */
  margin-left: 2.5vw;
  width: 40vw;
  /* border: 1px solid red; */
  height: 10vw;
}

.news-title {
  font-size: 20px;
  /* font-weight: 800; */
  height: 5vw;
  line-height: 6vw;
  /* border: 1px solid red; */
  text-align: left;
  /*1. 先强制一行内显示文本*/
  white-space: nowrap; /*默认 normal 自动换行*/
  /*2. 超出的部分隐藏*/
  overflow: hidden;
  /*3. 文字用省略号代替超出的部分*/
  text-overflow: ellipsis;
}

.news-title:hover {
  cursor: pointer;
  color: var(--theme-color);
}

@media screen and (max-width: 1200px) {
  .news-info {
    position: relative;
    left: -40px;
    display: block;
    /* margin: 0; */
    margin-top: 20px;
    margin-left: 0px;
    width: 290px;
    height: 80px;
    /* border: 1px solid red; */
  }

  .news-title {
    font-size: 16px;
    font-weight: 800;
    height: 30px;
    line-height: 20px;
    /* border: 1px solid red; */
    text-align: left;
    color: var(--theme-color);
  }

}

.news-text {
  height: 4vw;
  line-height: 2vw;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; /*弹性伸缩盒子模型显示*/
  -webkit-line-clamp: 2; /*限制在一个块元素显示的文本的行数*/
  -webkit-box-orient: vertical; /*设置或检索伸缩对象的子元素的排列方式*/
}

@media screen and (max-width: 1200px) {
  .news-text {
    height: 40px;
    line-height: 20px;
    text-align: left;
    color: rgb(44, 44, 44);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /*弹性伸缩盒子模型显示*/
    -webkit-line-clamp: 2; /*限制在一个块元素显示的文本的行数*/
    -webkit-box-orient: vertical; /*设置或检索伸缩对象的子元素的排列方式*/
  }
}

.news-detail {
  margin-top: 10px;

}

.page {
  margin-top: 20px;
  /* border: 1px solid red; */
}

.show-more {
  width: 90%;
  margin: auto;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  text-align: center;
  background-color: var(--background-light-color);
  color: var(--theme-color);
}

.show-more:hover {
  cursor: pointer;
}
</style>
